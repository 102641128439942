import Joi from "joi-browser";
import env from "./env";

const envVarsSchema = Joi.object({
  REACT_APP_API: Joi.string().uri().required(),
  NODE_ENV: Joi.string().required(),
})
  .unknown()
  .required();

const { error, value: envVars } = envVarsSchema.validate(env);
if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

const config = {
  api: envVars.REACT_APP_API,
  env: envVars.NODE_ENV,
};

console.log("Config", config);

export default config;
