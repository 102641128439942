import React, { useState, useEffect, useReducer } from "react";

import Press from "./Press";
import config from "./config/config";

const INTERVAL_TIME = 500;
const API = config.api;

const INITIAL_STATE = {
  position: "UP",
  continuous: false,
  power: true,
  isError: false,
  stopMe: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_POSITION":
      return { ...state, position: action.value };
    case "SET_CONTINUOUS":
      return { ...state, continuous: action.value };
    case "SET_POWER":
      return { ...state, power: action.value };
    case "SET_ERROR":
      return { ...state, isError: action.value };
    case "SET_STOP":
      return { ...state, stopMe: action.value };
    default:
      throw new Error();
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [machines, setMachines] = useState([]);
  const [machineId, setMachineId] = useState();
  console.log({ machineId });
  useEffect(() => {
    async function fetchMachines() {
      try {
        const response = await fetch(`${API}/api/v1/simulator/machines`);
        const machines = await response.json();
        const [machine] = machines;
        setMachines(machines);
        setMachineId(machine?._id);
      } catch (error) {
        console.error(error);
      }
    }

    fetchMachines();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (state.continuous) {
        console.log(
          "Setting position",
          state.position,
          "->",
          state.position === "UP" ? "DOWN" : "UP"
        );
        // setPosition('UP');
        dispatch({
          type: "SET_POSITION",
          value: state.position === "UP" ? "DOWN" : "UP",
        });
        console.log("Position", state.position);
        if (state.position === "DOWN" && state.stopMe) {
          dispatch({ type: "SET_STOP", value: false });
          dispatch({ type: "SET_CONTINUOUS", value: false });
        }
      }
    }, INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [state.continuous, state.position, state.stopMe]);

  async function start() {
    const response = await fetch(`${API}/api/v1/simulator/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    console.log("result", result);
    dispatch({ type: "SET_CONTINUOUS", value: true });
  }

  async function stop() {
    const response = await fetch(`${API}/api/v1/simulator/stop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    console.log("result", result);
    dispatch({ type: "SET_STOP", value: true });
  }

  async function reset() {
    const response = await fetch(`${API}/api/v1/simulator/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    console.log("result", result);
  }

  async function error() {
    await stop();
    const response = await fetch(`${API}/api/v1/simulator/error`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId, isError: !state.isError }),
    });
    const result = await response.json();
    dispatch({ type: "SET_ERROR", value: !state.isError });
    console.log("result", result);
  }

  return (
    <div>
      <div className="container">
        <div className="columns">
          <div className="column is-3">
            <div className="field">
              <div className="control">
                <div className="select is-large" style={{ marginTop: 50 }}>
                  <select
                    value={machineId}
                    onChange={(event) => setMachineId(event.target.value)}
                  >
                    {machines.map(({ _id, name }) => (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <Press power={state.power} position={state.position} />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2B3B4E", padding: "2.4rem 0 3rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column">
              <button
                className="button is-large is-fullwidth"
                onClick={() =>
                  dispatch({ type: "SET_POWER", value: !state.power })
                }
                type="button"
                aria-hidden
                tabIndex={0}
              >
                {state.power ? "OFF" : "ON"}
              </button>
            </div>
            <div className="column">
              <button
                className="button is-large is-fullwidth"
                onClick={reset}
                type="button"
                aria-hidden
                tabIndex={-1}
              >
                Reset
              </button>
            </div>
            <div className="column">
              <button
                className="button is-large is-danger is-fullwidth"
                disabled={!state.power}
                onClick={error}
                type="button"
                aria-hidden
                tabIndex={0}
              >
                {!state.isError ? "Error" : "Fix error"}
              </button>
            </div>
            <div className="column">
              {state.continuous ? (
                <button
                  className="button is-interrupt is-large is-fullwidth"
                  onClick={stop}
                  disabled={!state.power || state.stopMe}
                  type="button"
                  aria-hidden
                  tabIndex={0}
                >
                  Stop
                </button>
              ) : (
                <button
                  className="button is-production is-large is-fullwidth"
                  onClick={start}
                  disabled={!state.power || state.isError}
                  type="button"
                  aria-hidden
                  tabIndex={0}
                >
                  Continuous
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
